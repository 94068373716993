import type { VirtualElement } from "@floating-ui/react";
import React, { useImperativeHandle, useRef, useState } from "react";

import { isNative } from "@kraaft/helper-functions";
import { useBooleanState } from "@kraaft/helper-hooks";

import {
  ActionSheetContent,
  ActionSheetItem,
  type ActionSheetItemProps,
} from "../../actionSheet";
import type { ActionSheetItemPropsWithKey } from "./contextMenu.types";
import { ContextMenuSheetDefinition } from "./contextMenuSheet";

export type ImperativeContextMenuHandle = {
  openWith: (
    id: string,
    data: Array<ActionSheetItemPropsWithKey>,
    virtualAnchor?: VirtualElement,
  ) => void;
  updateState: (id: string, data: Array<ActionSheetItemPropsWithKey>) => void;
};

export const ImperativeActionContextMenu = React.forwardRef<
  ImperativeContextMenuHandle,
  unknown
>((_, ref) => {
  const [isOpen, setOpen, setClose] = useBooleanState(false);
  const [data, setData] = useState<Array<ActionSheetItemPropsWithKey>>([]);
  const virtualElement = useRef<VirtualElement | null>(null);
  const idRef = useRef<string | undefined>(undefined);

  useImperativeHandle(
    ref,
    () =>
      ({
        openWith(
          id: string,
          _data: Array<ActionSheetItemProps>,
          _virtualElement?: VirtualElement,
        ) {
          setData(_data);
          if (!isNative()) {
            if (!_virtualElement) {
              console.error(
                "You can't open the contextMenu without a virtualElement on the web",
              );
              return;
            }
            virtualElement.current = _virtualElement;
          }
          setOpen();
          idRef.current = id;
        },
        updateState(id: string, _data: Array<ActionSheetItemProps>) {
          if (idRef.current === id) {
            setData(_data);
          }
        },
      }) satisfies ImperativeContextMenuHandle,
    [setOpen],
  );

  return (
    <ContextMenuSheetDefinition.Host
      open={isOpen}
      onClose={setClose}
      anchor={virtualElement}
      withoutBackdrop
    >
      <ActionSheetContent>
        {data.map((actionData, index) => (
          <ActionSheetItem
            {...actionData}
            onClick={() => {
              actionData.onClick();
              setClose();
            }}
            key={actionData.key || index}
          />
        ))}
      </ActionSheetContent>
    </ContextMenuSheetDefinition.Host>
  );
});
