import { pickBy } from "lodash";
import type React from "react";
import {
  type ComponentType,
  type ReactNode,
  useCallback,
  useState,
} from "react";
import { Platform, type PlatformOSType } from "react-native";
import {
  assert,
  type RequiredKeys,
  type UnionToIntersection,
} from "ts-essentials";

import { useBooleanState } from "@kraaft/helper-hooks";

import { anchoredSheetDefinition } from "./anchoredSheet/anchoredSheet.definition";
import { bottomSheetDefinition } from "./bottomSheet/bottomSheet.definition";
import { popupSheetDefinition } from "./popupSheet/popupSheet.definition";
import type { SheetDefinition } from "./sheet.types";

const sheetFactory = {
  bottom: bottomSheetDefinition,
  anchored: anchoredSheetDefinition,
  popup: popupSheetDefinition,
} satisfies { [variant: string]: SheetDefinition };

type AvailableVariantForPlatform = {
  native: "bottom";
  ios: "bottom";
  android: "bottom";
  web: "anchored" | "popup";
} & { [P in PlatformOSType]: SheetVariant };

type SheetFactoryType = typeof sheetFactory;
type SheetVariant = keyof SheetFactoryType;

type Specifics = { [P in PlatformOSType]?: AvailableVariantForPlatform[P] } & {
  default?: SheetVariant;
};

type ExtractVariant<S extends Specifics> = Extract<S[keyof S], SheetVariant>;

/*
Remaining is better than Omit<P, keyof PT> because it better handle undefined
type A = { a: string };
type B = { a: undefined };
type C = Omit<A, keyof B>; // -> C = {}
type D = Remaining<A, B>;  // -> D = {a: string}
 */
type Remaining<P, PT extends Partial<P>> = Omit<
  P,
  { [k in keyof P]: PT[k] extends P[k] ? k : never }[keyof P]
>;

function getSheet<S extends Specifics>(
  specifics: S,
): SheetFactoryType[ExtractVariant<S>] {
  const variantForPlatform = Platform.select(specifics);

  if (variantForPlatform !== undefined) {
    return sheetFactory[variantForPlatform as ExtractVariant<S>];
  }

  assert(false, "sheet variant is not defined for platform");
}

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

export function Sheet<S extends Specifics>(specifics: S) {
  const { Host, ...definition } = getSheet(specifics);

  type HostProps = UnionToIntersection<
    PropsOf<ReturnType<typeof getSheet<S>>["Host"]>
  >;
  type PartialHostProps = Partial<HostProps>;

  // Merge the props of the Host component with the props of the wrapped component
  // Allows for easier usage of the sheets
  function Wrap<P, StaticProps extends PartialHostProps>(
    Component: React.FC<P>,
    staticProps?: StaticProps,
  ) {
    type WrappedComponentProps = HostProps & P;
    type OnlySetKeys = RequiredKeys<StaticProps>;
    type SheetContentProps = Omit<
      WrappedComponentProps,
      OnlySetKeys | "open" | "onClose" | "children"
    > & {
      [k in keyof StaticProps]?: StaticProps[k] | undefined;
    };

    const WrappedComponent = (props: WrappedComponentProps) => (
      <Host
        {...(staticProps ?? {})}
        {...(pickBy(props as any, (element) => element !== undefined) as any)}
      >
        <Component {...(props as any)} />
      </Host>
    );

    function useSheet<PartialProps extends Partial<SheetContentProps>>(
      props: PartialProps,
    ): {
      isOpen: boolean;
      open: (
        ...args: RequiredKeys<
          Remaining<SheetContentProps, PartialProps>
        > extends never
          ? []
          : [params: Remaining<SheetContentProps, PartialProps>]
      ) => void;
      close: () => void;
      element: ReactNode;
    } {
      const [isOpen, setOpen, close] = useBooleanState();
      const [state, setState] = useState<
        Remaining<SheetContentProps, PartialProps> | undefined
      >(undefined);

      const open = useCallback(
        (params: Remaining<SheetContentProps, PartialProps> | undefined) => {
          setState(params);
          setOpen();
        },
        [setOpen],
      );

      return {
        isOpen,
        open: open as any,
        close,
        element: (
          <WrappedComponent
            open={isOpen}
            onClose={close}
            {...({
              ...pickBy(props, (element) => element !== undefined),
              ...pickBy(state, (element) => element !== undefined),
            } as any)}
          />
        ),
      };
    }

    WrappedComponent.use = useSheet;

    WrappedComponent.withDefaults = <Se extends PartialHostProps>(props: Se) =>
      Wrap(Component, props);

    return WrappedComponent;
  }

  // Function to create an inlined sheet component
  const create = <P = HostProps>(
    createFn: (p: typeof definition) => React.FC<P & HostProps>,
  ) => {
    return Wrap(createFn(definition));
  };

  const result = {
    Host: Host as ComponentType<HostProps>,
    Paper: definition.Paper,
    Header: definition.Header,
    Content: definition.Content,
    GrowingContent: definition.GrowingContent,
    Footer: definition.Footer,
    Wrap,
    create,
  };

  return result;
}
