import type { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { Spacing } from "../../constants";
import { Portal, PortalHost } from "../../layout";
import {
  type CarouselPageProps,
  type CarouselPlaceProps,
  useCarouselIsFocused,
  useCarouselShouldRender,
} from "./carouselContext";

const Elements = {
  title(children: ReactNode, style: StyleProp<ViewStyle> | undefined) {
    return <View style={[{ padding: Spacing.S8 }, style]}>{children}</View>;
  },
  caption(children: ReactNode, style: StyleProp<ViewStyle> | undefined) {
    return <View style={style}>{children}</View>;
  },
  tool(children: ReactNode, style: StyleProp<ViewStyle> | undefined) {
    return <View style={style}>{children}</View>;
  },
  bottom(children: ReactNode, style: StyleProp<ViewStyle> | undefined) {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          },
          style,
        ]}
      >
        {children}
      </View>
    );
  },
} as const;

type Element = keyof typeof Elements;

export function createElementHolder(name: Element, id: string) {
  const prefixedId = `${name}-${id}`;

  return {
    component: ({
      children,
      index,
      forwardMeshContext,
      style,
    }: CarouselPlaceProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const isFocused = useCarouselIsFocused(index);
      if (!isFocused) {
        return null;
      }
      return (
        <Portal hostname={prefixedId} forwardMeshContext={forwardMeshContext}>
          {Elements[name](children, style)}
        </Portal>
      );
    },
    portalHost: <PortalHost hostname={prefixedId} />,
  };
}

export const Page = ({ children, index }: CarouselPageProps) => {
  const shouldRender = useCarouselShouldRender(index);

  if (!shouldRender) {
    return null;
  }

  return children;
};
