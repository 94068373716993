import { VirtualElement } from "@floating-ui/react";
import pickBy from "lodash/pickBy";
import React, { ReactNode, useCallback, useState } from "react";
import { RequiredKeys, UnionToIntersection } from "ts-essentials";

import { useBooleanState } from "@kraaft/helper-hooks";
import { ReactNodeRef } from "@kraaft/shared/core/types";

import { ActionSheetContent, ActionSheetDefinition } from "./actionSheet";
import { ActionSheetItem } from "./actionSheetItem";

type Remaining<P, PT extends Partial<P>> = Omit<
  P,
  { [k in keyof P]: PT[k] extends P[k] ? k : never }[keyof P]
>;

export function ActionSheet() {
  const { Host } = ActionSheetDefinition;

  type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

  type HostProps = UnionToIntersection<PropsOf<typeof Host>>;
  type PartialHostProps = Partial<HostProps>;

  // Merge the props of the Host component with the props of the wrapped component
  // Allows for easier usage of the sheets
  function Wrap<P, StaticProps extends PartialHostProps>(
    Component: React.FC<P>,
    staticProps?: StaticProps,
  ) {
    type WrappedComponentProps = HostProps & P;
    type OnlySetKeys = RequiredKeys<StaticProps>;
    type SheetContentProps = Omit<
      WrappedComponentProps,
      OnlySetKeys | "open" | "onClose" | "children"
    > & {
      [k in keyof StaticProps]?: StaticProps[k] | undefined;
    };

    const WrappedComponent = (props: WrappedComponentProps) => (
      <Host
        {...(staticProps ?? {})}
        {...(pickBy(props as any, (element) => element !== undefined) as any)}
      >
        <Component {...(props as any)} />
      </Host>
    );

    function useSheet<PartialProps extends Partial<SheetContentProps>>(
      props: PartialProps,
    ): {
      isOpen: boolean;
      open: (
        ...args: RequiredKeys<
          Remaining<SheetContentProps, PartialProps>
        > extends never
          ? []
          : [params: Remaining<SheetContentProps, PartialProps>]
      ) => void;
      close: () => void;
      openWithAnchor: (
        anchor: VirtualElement,
        ...args: RequiredKeys<
          Remaining<SheetContentProps, PartialProps>
        > extends never
          ? []
          : [params: Remaining<SheetContentProps, PartialProps>]
      ) => void;
      openWithAnchorRef: (
        anchor: ReactNodeRef,
        ...args: RequiredKeys<
          Remaining<SheetContentProps, PartialProps>
        > extends never
          ? []
          : [params: Remaining<SheetContentProps, PartialProps>]
      ) => void;
      element: ReactNode;
    } {
      const [isOpen, setOpen, close] = useBooleanState();
      const [state, setState] = useState<
        Remaining<SheetContentProps, PartialProps> | undefined
      >(undefined);
      const [anchor, setAnchor] = useState<VirtualElement | undefined>(
        undefined,
      );

      const open = useCallback(
        (params: Remaining<SheetContentProps, PartialProps> | undefined) => {
          setAnchor(undefined);
          setState(params);
          setOpen();
        },
        [setOpen],
      );

      const openWithAnchor = useCallback(
        (
          newAnchor: VirtualElement,
          ...args: RequiredKeys<
            Remaining<SheetContentProps, PartialProps>
          > extends never
            ? []
            : [params: Remaining<SheetContentProps, PartialProps>]
        ) => {
          setAnchor(newAnchor);
          setOpen();
        },
        [setOpen],
      );

      const openWithAnchorRef = useCallback(
        (
          anchorRef: ReactNodeRef,
          ...args: RequiredKeys<
            Remaining<SheetContentProps, PartialProps>
          > extends never
            ? []
            : [params: Remaining<SheetContentProps, PartialProps>]
        ) => {
          if (!anchorRef.current) {
            return;
          }
          openWithAnchor(anchorRef.current, ...args);
        },
        [openWithAnchor],
      );

      return {
        isOpen,
        open: open as any,
        openWithAnchor,
        openWithAnchorRef,
        close,
        element: (
          <WrappedComponent
            open={isOpen}
            onClose={close}
            {...({
              ...pickBy(props, (element) => element !== undefined),
              ...pickBy(state, (element) => element !== undefined),
            } as any)}
            anchor={anchor ? { current: anchor } : props.anchor}
          />
        ),
      };
    }

    WrappedComponent.use = useSheet;

    WrappedComponent.withDefaults = <Se extends PartialHostProps>(props: Se) =>
      Wrap(Component, props);

    return WrappedComponent;
  }

  // Function to create an inlined sheet component
  const create = <P = HostProps>(
    createFn: (p: {
      ActionSheetContent: typeof ActionSheetContent;
      ActionSheetItem: typeof ActionSheetItem;
    }) => React.FC<P & HostProps>,
  ) => {
    return Wrap(createFn({ ActionSheetContent, ActionSheetItem }));
  };

  return { create };
}
